<script lang="ts">
	import { goto } from '$app/navigation'
	import { sendGTM } from '$lib/analytics'
	import { INSTALLATION_CATEGORY, LEAD_SOURCE } from '$lib/consts'
	import { DOCUMENTS, PRIVACY_POLICY, MARKETPLACE, ABOUT_US } from '$lib/routes'
	import { getPreLeadModalState, getMarketplaceContactInputState } from '$lib/stores'
	import { t } from '$lib/translations'
	import { setLeadCookie, validatePhone } from '$lib/utils'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
	import { BROWSER } from 'esm-env'
	import { X, Check } from 'lucide-svelte'
	import { Field, Control, Label, FieldErrors } from 'formsnap'
	import { superForm, defaults } from 'sveltekit-superforms'
	import { zodClient, zod } from 'sveltekit-superforms/adapters'
	import { z } from 'zod'
	import { Checkbox, Label as BitsLabel } from 'bits-ui'
	import { onMount } from 'svelte'

	const preLeadModalState = getPreLeadModalState()
	const marketplaceContactInputState = getMarketplaceContactInputState()

	let formStep: 'DEFAULT' | 'SENDING' | 'SENT' = 'DEFAULT'
	let errorMessage = ''

	const consentCheckboxGeneral =
		PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_PL' || PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_IT'

	const consentCheckboxChannels = PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_PL'

	const stars = 4.5

	export const schema = z.object({
		email: z
			.string()
			.min(1, t('web.forms.email.required'))
			.pipe(z.string().email(t('web.forms.email.validate'))),
		phone: z
			.string()
			.min(1, t('web.forms.phone.required'))
			.pipe(
				z.string().refine(async (value) => {
					const validationResult = await validatePhone(value)
					return validationResult.isValid
				}, t('web.forms.phone.validate') || '')
			),
		marketingConsentGeneral: z
			.boolean()
			.default(false)
			.refine((value) => (consentCheckboxGeneral ? value : true), t('web.forms.field.required')),
		marketingConsentEmail: z
			.boolean()
			.default(false)
			.refine((value) => (consentCheckboxChannels ? value : true), t('web.forms.field.required')),
		marketingConsentPhone: z
			.boolean()
			.default(false)
			.refine((value) => (consentCheckboxChannels ? value : true), t('web.forms.field.required')),
		marketingConsentPost: z
			.boolean()
			.default(false)
			.refine((value) => (consentCheckboxChannels ? value : true), t('web.forms.field.required'))
	})

	const form = superForm(defaults(zod(schema)), {
		SPA: true,
		validators: zodClient(schema),
		onUpdate: async ({ form }) => {
			if (form.valid) {
				formStep = 'SENDING'
				try {
					const withWater = $preLeadModalState.params?.heatingPurpose === 'WITH_WATER'
					const response = await fetch('/api/lead/create', {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({
							houseHeatType: $preLeadModalState.params?.houseHeatType,
							zip: $preLeadModalState.params?.zip,
							email: $formData.email,
							phone: $formData.phone,
							leadSource:
								$preLeadModalState.params?.formType === 'pv'
									? LEAD_SOURCE.PV_BLOG
									: LEAD_SOURCE.HVAC_BLOG,
							...((withWater === true || withWater === false) && {
								waterHeatingWithHouseHeatSource: withWater
							}),
							...($preLeadModalState.params?.annualConsumption && {
								annualElectricityConsumption: $preLeadModalState.params?.annualConsumption
							}),
							installationProductCategory:
								$preLeadModalState.params?.formType === 'pv'
									? INSTALLATION_CATEGORY['FOTOVOLTAIKA']
									: INSTALLATION_CATEGORY['TEPELNE_CERPADLO']
						})
					})
					if (response.ok) {
						const data: any = await response.json()
						const leadUid = data?.leadCreate?.uid || ''

						const gotoUrl = new URL('https://example.com')

						const urlParams = {
							houseHeatType: $preLeadModalState.params?.houseHeatType,
							waterHeatingWithHouseHeatSource: withWater,
							annualElectricityConsumption: $preLeadModalState.params?.annualConsumption,
							formType: $preLeadModalState.params?.formType,
							zip: $preLeadModalState.params?.zip,
							leadUid: leadUid
						}

						Object.entries(urlParams).forEach(([key, value]) => {
							if (value) gotoUrl.searchParams.set(key, value)
						})

						const gtmEventName =
							$preLeadModalState.params?.formType === 'pv'
								? 'bloglead_form_sent_pv'
								: 'bloglead_form_sent_hvac'

						sendGTM(
							gtmEventName,
							{},
							{
								transaction_id: leadUid || '',
								_clear: true
							}
						)
						const event = { detail: { leadUid: leadUid } }
						setLeadCookie(event, $preLeadModalState.params?.formType ?? 'hvac')

						await goto(`/${MARKETPLACE}${gotoUrl.search}`)

						preLeadModalState.update((state) => ({
							...state,
							open: false
						}))
						formStep = 'DEFAULT'
					} else {
						formStep = 'DEFAULT'
						logger.error('components.preLeadModal.error', 'Failed to submit form', response)
					}
				} catch (error) {
					formStep = 'DEFAULT'
					errorMessage =
						t('web.forms.error.submit', {
							class: 'underline',
							link: `/${ABOUT_US}`
						}) || ''
					return {}
				}
			} else {
				formStep = 'DEFAULT'
			}
		},
		onChange: () => {
			errorMessage = ''
		}
	})

	const { form: formData, enhance: enhance, errors } = form

	onMount(() => {
		if ($marketplaceContactInputState.email) $formData.email = $marketplaceContactInputState.email
		if ($marketplaceContactInputState.phone) $formData.phone = $marketplaceContactInputState.phone
	})

	$: preLeadModalState.subscribe((state) => {
		if (BROWSER) {
			if (state.open === true) {
				const scrollX = document.querySelector('html')?.scrollTop || 0
				window.onscroll = () => window.scrollTo(0, scrollX)
			} else {
				window.onscroll = (event) => event
			}
		}
	})
</script>

{#if $preLeadModalState?.open}
	<div
		class="hide-scrollbar animate-opacity fixed left-0 top-0 z-[10000] flex h-full w-full items-start justify-center overflow-y-scroll bg-neutral-300/20 backdrop-blur-sm"
	>
		<div
			class="absolute h-full w-full"
			role="none"
			on:click={() => {
				preLeadModalState.update((state) => ({
					...state,
					open: false
				}))
			}}
		/>
		<div class="animate-in flexw-full relative z-[10000] items-center justify-center sm:my-8">
			<div
				class="wrapper relative mx-auto flex min-h-fit w-full max-w-full flex-col overflow-hidden bg-white pb-[50vh] shadow-xl shadow-neutral-600/5 sm:pb-0 md:w-[600px] md:rounded-3xl"
			>
				<div
					class="relative flex w-full basis-1/2 flex-col items-center justify-center bg-w-blue-950 py-12 pb-8 sm:pt-20"
				>
					<img src="/images/logos/woltair-w-red.svg" alt="Woltair logo" class="w-14" />
					<h2 class="px-4 text-center text-4.5xl font-semibold !leading-snug text-white sm:px-12">
						{$preLeadModalState.params.formType
							? t(`web.forms.preLead.title.${$preLeadModalState.params.formType}`)
							: t(`web.forms.preLead.title`)}
					</h2>
					{#if PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_CZ'}
						<div class="flex flex-wrap items-center justify-center gap-x-4 gap-y-1 text-white">
							<img
								src="/images/logos/refsite-white.webp"
								alt="rating logo"
								class="h-fit object-contain"
								height="32"
								width="80"
							/>
							{#if stars}
								<div class="flex items-center gap-2">
									<div class="flex flex-col gap-1">
										<div class="flex gap-1">
											{#each Array(Math.floor(stars)) as _}
												<img
													src="/images/icons/rating-star.svg"
													class="aspect-square h-[1em]"
													width="16"
													height="16"
													alt="rating"
													loading="lazy"
												/>
											{/each}
											{#if Math.floor(stars) !== Math.ceil(stars)}
												<img
													src="/images/icons/rating-star-half.svg"
													class="aspect-square h-[1em]"
													width="16"
													height="16"
													alt="rating"
													loading="lazy"
												/>
											{/if}
										</div>
									</div>
								</div>
							{/if}
							<span class="flex items-center gap-2 gap-x-3 text-sm underline-offset-2"
								>93 recenzí
							</span>
						</div>
					{/if}
				</div>
				<div class="w-full basis-1/2 px-4 py-8 sm:p-8 sm:pt-6">
					<div class="text-center text-sm">
						<div class="opacity-70">
							<p>{@html t('web.forms.preLead.subtext1')}</p>
							<p>{@html t('web.forms.preLead.subtext2')}</p>
						</div>

						<form use:enhance class="flex flex-col items-start transition-opacity" method="POST">
							<Field {form} name="email">
								<Control let:attrs>
									<Label class="text-sm">{t('web.forms.email')}</Label>

									<input
										{...attrs}
										type="text"
										data-testid="modal-email-input"
										bind:value={$formData.email}
										placeholder={t('web.forms.email.placeholder.example')}
										class="my-1 w-full rounded-md border-black/10 px-4 py-3 transition-colors focus:border-w-blue-950 focus:ring-w-blue-950 active:border-w-blue-950 active:ring-w-blue-950 md:text-sm"
										class:border-w-red-500={$errors.email}
									/>
									<FieldErrors class="text-sm text-w-red-500" />
								</Control>
							</Field>

							<Field {form} name="phone">
								<Control let:attrs>
									<Label class="mt-2 text-sm">
										{t('web.forms.phone')}
									</Label>

									<input
										{...attrs}
										type="text"
										data-testid="modal-phone-input"
										bind:value={$formData.phone}
										placeholder={t('web.forms.phone.placeholder')}
										class="my-1 w-full rounded-md border-black/10 px-4 py-3 transition-colors focus:border-w-blue-950 focus:ring-w-blue-950 active:border-w-blue-950 active:ring-w-blue-950 md:text-sm"
										class:border-w-red-500={$errors.phone}
									/>
									<FieldErrors class="mb-4 text-sm text-w-red-500" />
								</Control>
							</Field>

							{#if consentCheckboxGeneral}
								<Field {form} name="marketingConsentGeneral">
									<Control>
										<div class="flex items-center gap-x-4 py-2">
											<Checkbox.Root
												id="terms"
												data-testid="marketing-consent"
												aria-labelledby="terms-label"
												class="flex aspect-square h-8 w-8 items-center justify-center rounded-md border  bg-white transition-colors data-[state=checked]:border-w-red-500 data-[state=checked]:bg-w-red-500 {$errors.marketingConsentGeneral
													? 'border-w-red-500'
													: 'border-neutral-300'}"
												bind:checked={$formData.marketingConsentGeneral}
											>
												<Checkbox.Indicator
													class="text-background inline-flex items-center justify-center"
												>
													<Check size={24} color="white" />
												</Checkbox.Indicator>
											</Checkbox.Root>
											<BitsLabel.Root
												id="terms-label"
												for="terms"
												class="flex cursor-pointer flex-col text-sm font-medium leading-none"
											>
												<span class="text-left text-sm font-semibold text-neutral-500"
													>{@html t('web.forms.conditions.asCheckbox', {
														class: 'text-w-red-500',
														link: `/${DOCUMENTS}/${PRIVACY_POLICY}`
													})}</span
												>
											</BitsLabel.Root>
										</div>
										<FieldErrors class="pb-4 text-sm text-w-red-500" />
									</Control>
								</Field>
							{/if}

							{#if consentCheckboxChannels}
								<p class="unstyled mb-6 text-left text-sm font-light opacity-70">
									{t('web.forms.conditions.withOptions')}
								</p>
								<Field {form} name="marketingConsentEmail">
									<Control>
										<div class="flex items-center gap-x-4">
											<Checkbox.Root
												id="terms-email"
												data-testid="marketing-consent-email"
												aria-labelledby="terms-label-email"
												class="flex aspect-square h-8 w-8 items-center justify-center rounded-md border  bg-white transition-colors data-[state=checked]:border-w-red-500 data-[state=checked]:bg-w-red-500 {$errors.marketingConsentEmail
													? 'border-w-red-500'
													: 'border-neutral-300'}"
												bind:checked={$formData.marketingConsentEmail}
											>
												<Checkbox.Indicator
													class="text-background inline-flex items-center justify-center"
												>
													<Check size={24} color="white" />
												</Checkbox.Indicator>
											</Checkbox.Root>
											<BitsLabel.Root
												id="terms-label-email"
												for="terms-email"
												class="flex cursor-pointer flex-col text-sm font-medium leading-none"
											>
												<span class="text-sm font-semibold text-neutral-500"
													>{@html t('web.forms.conditions.email')}</span
												>
											</BitsLabel.Root>
										</div>
										<FieldErrors class="pb-4 text-sm text-w-red-500" />
									</Control>
								</Field>
								<Field {form} name="marketingConsentPhone">
									<Control>
										<div class="flex items-center gap-x-4">
											<Checkbox.Root
												id="terms-phone"
												data-testid="marketing-consent-phone"
												aria-labelledby="terms-label-phone"
												class="flex aspect-square h-8 w-8 items-center justify-center rounded-md border  bg-white transition-colors data-[state=checked]:border-w-red-500 data-[state=checked]:bg-w-red-500 {$errors.marketingConsentPhone
													? 'border-w-red-500'
													: 'border-neutral-300'}"
												bind:checked={$formData.marketingConsentPhone}
											>
												<Checkbox.Indicator
													class="text-background inline-flex items-center justify-center"
												>
													<Check size={24} color="white" />
												</Checkbox.Indicator>
											</Checkbox.Root>
											<BitsLabel.Root
												id="terms-label-phone"
												for="terms-phone"
												class="flex cursor-pointer flex-col text-sm font-medium leading-none"
											>
												<span class="text-sm font-semibold text-neutral-500"
													>{@html t('web.forms.conditions.phone')}</span
												>
											</BitsLabel.Root>
										</div>
										<FieldErrors class="pb-4 text-sm text-w-red-500" />
									</Control>
								</Field>
								<Field {form} name="marketingConsentPost">
									<Control>
										<div class="flex items-center gap-x-4">
											<Checkbox.Root
												id="terms-post"
												data-testid="marketing-consent-post"
												aria-labelledby="terms-label-post"
												class="flex aspect-square h-8 w-8 items-center justify-center rounded-md border  bg-white transition-colors data-[state=checked]:border-w-red-500 data-[state=checked]:bg-w-red-500 {$errors.marketingConsentPost
													? 'border-w-red-500'
													: 'border-neutral-300'}"
												bind:checked={$formData.marketingConsentPost}
											>
												<Checkbox.Indicator
													class="text-background inline-flex items-center justify-center"
												>
													<Check size={24} color="white" />
												</Checkbox.Indicator>
											</Checkbox.Root>
											<BitsLabel.Root
												id="terms-label-post"
												for="terms-post"
												class="flex cursor-pointer flex-col text-sm font-medium leading-none"
											>
												<span class="text-sm font-semibold text-neutral-500"
													>{@html t('web.forms.conditions.post')}</span
												>
											</BitsLabel.Root>
										</div>
										<FieldErrors class="pb-4 text-sm text-w-red-500" />
									</Control>
								</Field>
							{/if}
							<button
								class:pointer-events-none={formStep === 'SENDING'}
								class:opacity-70={formStep === 'SENDING'}
								data-testid="modal-submit-button"
								type="submit"
								class="btn-new btn-new-blue z-10 w-full whitespace-normal rounded-md sm:rounded-lg"
							>
								{#if formStep === 'SENDING'}
									<img
										src="/images/icons/loader-2.svg"
										class="w-[1.25em] animate-spin"
										alt="Loader icon"
									/>{t('web.forms.preLead.submitting')}
								{:else}
									{t('web.forms.preLead.submit')}
								{/if}
							</button>

							{#if errorMessage}
								<p class="mt-2 text-center text-sm text-w-red-500">{@html errorMessage}</p>
							{/if}
							<p
								class="unstyled my-4 text-center text-xs font-light !leading-tight text-neutral-500"
							>
								{@html t('web.forms.conditions', {
									class: 'text-primary-500',
									link: `/${DOCUMENTS}/${PRIVACY_POLICY}`
								})}
							</p>
						</form>
					</div>
				</div>
			</div>
			<button
				class="absolute right-0 top-0 z-10 rounded-bl-3xl px-4 py-3.5"
				on:click={() => {
					preLeadModalState.update((state) => ({
						...state,
						open: false
					}))
				}}
				><X color="#fff" class="hidden sm:block" size={16} />
				<X color="#fff" class="sm:hidden" size={24} /></button
			>
		</div>
	</div>
{/if}

<style lang="postcss">
	.animate-in {
		animation: slide-in 0.25s ease-out both;
	}

	.animate-opacity {
		animation: opacity-in 0.25s ease-out both;
	}

	@keyframes slide-in {
		0% {
			transform: translateY(-20%) scale(0.5);
			opacity: 0;
		}
		100% {
			transform: translateY(0) scale(1);
			opacity: 1;
		}
	}

	@keyframes opacity-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
