<script lang="ts">
	import { string, object } from 'yup'
	import { goto } from '$app/navigation'
	import { sendGTM } from '$lib/analytics'
	import { CURRENCY_BY_COUNTRY } from '$lib/consts'
	import { INSTALLATION_CATEGORY } from '$lib/consts'
	import { formatCurrency } from '$lib/formatters'
	import { DOCUMENTS, PRIVACY_POLICY, MARKETPLACE } from '$lib/routes'
	import { getGrantsModalState } from '$lib/stores'
	import { t } from '$lib/translations'
	import { validatePhone } from '$lib/utils'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	const grantsModalState = getGrantsModalState()

	let firstValidationDone = false
	let formStep: 'DEFAULT' | 'SENDING' | 'SENT' = 'DEFAULT'

	$: subsidyAmount = $grantsModalState?.device?.subsidy || $grantsModalState?.device?.grant

	// for HVAC
	const catalogPackageIds = $grantsModalState?.device?.catalogId
		? [$grantsModalState?.device?.catalogId]
		: null

	// for PV
	const catalogPackageIdsWithAmount = {
		items: $grantsModalState?.device?.components?.map((component: any) => {
			return {
				packageId: component.packageId,
				amount: component.quantity
			}
		})
	}

	const formData: Record<string, string> = {
		email: '',
		phone: ''
	}

	const formErrors: Record<string, string> = {
		email: '',
		phone: ''
	}

	const schema = object({
		email: string().required(t('web.forms.email.required')).email(t('web.forms.email.validate')),
		phone: string()
			.required(t('web.forms.leaveContact.phone.validation'))
			.test(
				'phone-validation',
				t('web.forms.leaveContact.phone.format') || '',
				async function (value) {
					if (!value) return false
					const validationResult = await validatePhone(value)
					return validationResult.isValid
				}
			)
	})

	async function validateField(fieldName: string, value: string) {
		firstValidationDone = true
		try {
			await schema.validateAt(fieldName, { [fieldName]: value })
			formErrors[fieldName] = ''
		} catch (error: any) {
			formErrors[fieldName] = error.message
		}
	}

	async function handleSubmit(e: Event) {
		e.preventDefault()
		try {
			const withWater = $grantsModalState.params?.heatingPurpose === 'WITH_WATER'
			await Promise.all(
				Object.entries(formData).map(async ([key, value]) => {
					await validateField(key, value.toString() || '')
				})
			)
			if (Object.values(formErrors).some((error) => error)) throw new Error('Validation failed')

			formStep = 'SENDING'
			const response = await fetch('/api/lead/create', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					houseHeatType: $grantsModalState.params?.houseHeatType,
					zip: $grantsModalState.params?.zip,
					email: formData.email,
					phone: formData.phone,
					leadSource: 'GRANT_CHECK',
					...((withWater === true || withWater === false) && {
						waterHeatingWithHouseHeatSource: withWater
					}),
					...($grantsModalState.params?.annualConsumption && {
						annualElectricityConsumption: $grantsModalState.params?.annualConsumption
					}),
					installationProductCategory:
						$grantsModalState.params?.formType === 'pv'
							? INSTALLATION_CATEGORY['FOTOVOLTAIKA']
							: INSTALLATION_CATEGORY['TEPELNE_CERPADLO'],
					...(catalogPackageIds &&
						$grantsModalState.params?.formType === 'hvac' && {
							catalogPackageIds: catalogPackageIds
						}),
					...(catalogPackageIdsWithAmount?.items &&
						$grantsModalState.params?.formType === 'pv' && {
							catalogPackageIdsWithAmount: catalogPackageIdsWithAmount
						})
				})
			})

			if (response.ok) {
				formStep = 'SENT'

				const data: any = await response.json()
				const leadUid = data?.leadCreate?.uid || ''

				const gotoUrl = new URL('https://example.com')

				const urlParams = {
					houseHeatType: $grantsModalState.params?.houseHeatType,
					waterHeatingWithHouseHeatSource: withWater,
					annualElectricityConsumption: $grantsModalState.params?.annualConsumption,
					formType: $grantsModalState.params?.formType,
					zip: $grantsModalState.params?.zip,
					leadUid: leadUid,
					order: $grantsModalState.params?.formType === 'pv' ? 'grant' : 'subsidy'
				}

				Object.entries(urlParams).forEach(([key, value]) => {
					if (value) gotoUrl.searchParams.set(key, value)
				})

				const gtmEventName =
					$grantsModalState.params?.formType === 'pv'
						? 'subsidy_form_sent_fve'
						: 'subsidy_form_sent_hvac'

				sendGTM(
					gtmEventName,
					{},
					{
						transaction_id: leadUid || '',
						_clear: true
					}
				)

				goto(`/${MARKETPLACE}${gotoUrl.search}`)

				grantsModalState.update((state) => ({
					...state,
					open: false
				}))
			} else {
				formStep = 'DEFAULT'
				logger.error('components.grantsModal.error', 'Failed to submit form', response)
			}
		} catch (error: any) {
			if (error.inner) {
				if (error.inner.length > 0)
					error.inner.forEach((err: any) => {
						formErrors[err.path] = err.message
					})
			}
		}
	}
</script>

{#if $grantsModalState?.open}
	<div class="fixed left-0 top-0 z-[10000] h-full w-full backdrop-blur-sm">
		<div
			class="fixed top-20 h-full w-full bg-neutral-100 opacity-80"
			tabindex="0"
			role="button"
			on:keydown
			on:click={() => {
				grantsModalState.update((state) => ({
					...state,
					open: false
				}))
			}}
		/>
		<div class="flex h-screen items-start justify-center overflow-y-auto px-2 py-12 sm:pt-12">
			<div
				class="wrapper relative z-10 mx-auto flex min-h-fit w-[600px] max-w-full flex-col overflow-hidden rounded-3xl bg-white shadow-xl shadow-neutral-600/5"
			>
				<div
					class="relative flex w-full basis-1/2 flex-col items-center justify-center bg-w-blue-950 py-12 pb-8 sm:pt-20"
				>
					<button
						on:click={() =>
							grantsModalState.update((state) => ({
								...state,
								open: false
							}))}
						><img
							src="/images/icons/close-x.svg"
							alt="Close icon"
							class="absolute right-5 top-5 w-3 transition-opacity hover:opacity-80"
						/></button
					>
					<div class="absolute left-7 top-5 text-sm text-white">
						{$grantsModalState.params.formType === 'pv'
							? t('web.forms.photovoltaics')
							: t('web.forms.heatPump')}
					</div>
					<img src="/images/logos/woltair-w-red.svg" alt="Woltair logo" class="w-14" />
					<h2 class="px-4 text-center text-4.5xl font-semibold !leading-snug text-white sm:px-12">
						{#if subsidyAmount >= 0}
							{@html t('web.grants.modal.maxSubsidy.withCurrency', {
								amount: subsidyAmount
									?.toFixed(0)
									.toString()
									.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
									.replace(/ /g, '\u00a0')
									.trim(),
								currency: formatCurrency(CURRENCY_BY_COUNTRY)
							})}{#if PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_IT'}*{/if}
						{:else}
							{@html t('web.grants.modal.noSubsidy')}
						{/if}
					</h2>
					{#if PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_IT' && $grantsModalState?.device?.subsidy >= 0}
						<div class="mt-2 px-4 text-center text-xs text-white">
							*{t('web.grants.modal.maxSubsidy.notice')}
						</div>
					{/if}
				</div>
				<div class="w-full basis-1/2 px-4 py-8 sm:p-8 sm:pt-6">
					<div class="text-center text-sm">
						<div class="opacity-70">
							{#if subsidyAmount >= 0}
								{t('web.grants.modal.maxSubsidy.subtext')}
							{:else}
								{t('web.grants.modal.noSubsidy.subtext')}
							{/if}
						</div>

						<form class="mt-8 flex flex-col items-start" on:submit={handleSubmit}>
							<label for="email" class="text-sm">{t('web.forms.email.longer')}</label>
							<input
								type="text"
								name="email"
								class="mt-2 w-full rounded-md border-black/10 p-4 text-sm placeholder:opacity-60"
								placeholder={t('web.forms.email.placeholder.example')}
								bind:value={formData.email}
								on:change={() => validateField('email', formData.email)}
								class:border-w-red-500={formErrors.email}
							/>
							{#if formErrors.email}
								<div class="mt-1 text-sm text-w-red-500">{formErrors.email}</div>
							{/if}

							<label for="phone" class="mt-4 text-sm">{t('web.forms.yourPhone')}</label>
							<input
								type="text"
								name="phone"
								class="mt-2 w-full rounded-md border-black/10 p-4 text-sm placeholder:opacity-60"
								placeholder={t('web.forms.phone.placeholder')}
								bind:value={formData.phone}
								on:change={() => validateField('phone', formData.phone)}
								class:border-w-red-500={formErrors.phone}
							/>
							{#if formErrors.phone}
								<div class="mt-1 text-sm text-w-red-500">{formErrors.phone}</div>
							{/if}

							<button
								type="submit"
								class="btn-new relative mt-6 w-full {formData.email &&
								!formErrors.email &&
								formData.phone &&
								!formErrors.phone &&
								firstValidationDone
									? 'btn-new-red'
									: 'btn-new-disabled'}"
							>
								{#if formStep === 'SENDING'}
									<img
										src="/images/icons/loader-2-black.svg"
										class="w-[1.25em] animate-spin"
										alt="Loader icon"
									/>{t('web.grants.modal.button.sending')}
								{:else}
									{t('web.grants.modal.button.default')}
								{/if}
							</button>
						</form>

						<div class="mt-4 text-sm font-light">
							{@html t('web.forms.conditions', {
								link: `/${DOCUMENTS}/${PRIVACY_POLICY}`,
								class: 'underline'
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
{/if}
